





















































































































































































import { computed, defineComponent, inject, PropType, reactive } from "@vue/composition-api";
import { Payout } from "@/interfaces/Payout";
import { SHOW_INVOICE } from "@/views/Payouts.vue";
import { userModule } from "@/store/modules/user";
import vuetify from "@/plugins/vuetify";
import { formatUnixTimestamp } from "@/helpers/timeUtils";

export default defineComponent({
  name: "PayoutCard",
  props: {
    payout: {
      type: Object as PropType<Payout>,
      default: () => null,
    },
  },
  setup: (props: { payout: Payout }) => {
    const breakpoints = vuetify.framework.breakpoint;
    const showPayout = inject<(payout: Payout | null) => void>(SHOW_INVOICE);

    const persOrOrgNr = computed(() => {
      const length = userModule.state.account.personNumber.length;
      const personOrOrgNumber = length === 11 ? "Personnr." : "Org.nr.";
      return `${personOrOrgNumber} ${userModule.state.account.personNumber}`;
    });

    const fee = computed(() => {
      if (props.payout.payment) {
        return Number(props.payout.payment.fee / 100).toLocaleString("no");
      }
      return 0;
    });

    const vm = reactive({
      ID: props.payout.ID,
      amount: Number(props.payout.amount).toLocaleString("no"),
      created: formatUnixTimestamp(props.payout.created, "LLLL"),
      paid: formatUnixTimestamp(props.payout.paid, "LL"),
      user: {
        name: `${userModule.state.profile.firstName} ${userModule.state.profile.lastName}`,
        address: userModule.state.account.address,
        postalCode: userModule.state.user.postalCode,
        city: userModule.state.account.city,
        personNumber: userModule.state.account.personNumber,
      },
    });

    return {
      breakpoints,
      fee,
      persOrOrgNr,
      showPayout,
      vm,
    };
  },
});
