import { DataTableHeader } from "vuetify";

export const PayoutTableHeaders: DataTableHeader[] = [
  {
    text: "Beløp",
    value: "amount",
  },
  {
    text: "Utbetalt",
    value: "arrival_date",
  },
  {
    text: "Status",
    value: "status",
    align: "center",
  },
  {
    text: "Faktura",
    value: "number",
    align: "end",
  },
];
