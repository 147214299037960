















import { defineComponent, PropType } from "@vue/composition-api";
import { PayoutTableHeaders } from "@/components/payouts/PayoutTableHeaders";
import { Payout } from "@/interfaces/Payout";
import PayoutsTableRow from "./PayoutsTableRow.vue";

export default defineComponent({
  name: "PayoutsTable",
  components: { PayoutsTableRow },
  props: {
    payouts: {
      type: Array as PropType<Payout[]>,
      default: () => [],
    },
  },
  setup: () => {
    return {
      headers: PayoutTableHeaders,
    };
  },
});
