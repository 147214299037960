import { Ref, ref } from "@vue/composition-api";

export const useLoading = (): {
  loading: Ref<boolean>,
  setLoading: (isLoading: boolean) => void
} => {
  const loading = ref(false);
  const setLoading = (isLoading: boolean): void => {
    loading.value = isLoading;
  };

  return { loading, setLoading };
};
