




























import { computed, defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import { Payout } from "@/interfaces/Payout";
import { formatUnixTimestamp } from "@/helpers/timeUtils";
import { useLoading } from "@/hooks/useLoading";
import { toNOLocale } from "@/helpers/viewUtils";

export default defineComponent({
  name: "PaidThisYear",
  props: {
    payouts: {
      type: Array as PropType<Payout[]>,
      default: () => [],
    },
  },
  setup: (props: { payouts: Payout[]; }) => {
    const payoutsSortedByYear = ref<{ year: string, totalPayout: number; }[]>([]);
    const totalAmountPerYear = ref<{ [year: string]: number; }>({});
    const activeYear = ref("");
    const { loading, setLoading } = useLoading();

    onMounted(() => calculatePayoutsForEachYear());

    const calculatePayoutsForEachYear = () => {
      setLoading(true);

      if (!props.payouts) return;

      for (const payout of props.payouts) {
        if (payout.status !== "paid") continue;
        const readableYear = formatUnixTimestamp(payout.paid, "YYYY");
        const payoutForYear = totalAmountPerYear.value[readableYear];
        totalAmountPerYear.value[readableYear] = (payoutForYear || 0) + (payout.amount);
      }

      payoutsSortedByYear.value = Object.entries(totalAmountPerYear.value).map(([year, totalPayout]) => {
        return {
          year,
          totalPayout,
        };
      }).sort((entryA, entryB) => entryA.year.localeCompare(entryB.year));
      activeYear.value = payoutsSortedByYear.value[payoutsSortedByYear.value.length - 1].year;

      setLoading(false);
    };

    const totalAmount = computed(() => {
      return toNOLocale(totalAmountPerYear.value[activeYear.value]);
    });

    return {
      activeYear,
      loading,
      payoutsSortedByYear,
      totalAmount,
      totalAmountPerYear,
    };
  },
});
